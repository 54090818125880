import React from "react";
import $ from 'jquery';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));


const ContactUs = () => {
  const intl = useIntl();
  const classes = useStyles();
  const initialFormData = Object.freeze({
    name: "",
    surname: "",
    email: "",
    organizationName: "",
    phonenumber: "",
    Address: "",
    WebsiteURL: "",
    message: "",
  });

  const formFields = [
    {
      id: "Name",
      name: "Name",
      type: "text",
      pattern: "^[a-zA-Z ]*$",
      maxLength: "250",
    },
    {
      id: "Surname",
      name: "Surname",
      type: "text",
      pattern: "^[a-zA-Z ]*$",
      maxLength: "250",
    },
    {
      id: "Email",
      name: "Email",
      type: "text",
      pattern: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
      maxLength: "250",
    },
    {
      id: "Organization Name",
      name: "Organization Name",
      type: "text",
      pattern: "^[a-zA-Z ]*$",
      maxLength: "250",
    },
    {
      id: "Phone Number",
      name: "Phone Number",
      displayName: "Correo Electrónico",
      type: "text",
      pattern: "[0-9\-]*",
      maxLength: "250",
    },
    {
      id: "Address",
      name: "Address",
      type: "text",
      pattern: "^[a-zA-Z ]*$",
      maxLength: "250",
    },
    {
      id: "Website URL",
      name: "Website URL",
      type: "text",
      pattern: "^[a-zA-Z ]*$",
      maxLength: "250",
    },
    {
      id: "Message",
      name: "Message",
      type: "textarea",
      pattern: "^[a-zA-Z ]*$",
      maxLength: "250",
    }
  ]

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [formData, updateFormData] = React.useState(initialFormData);

  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [snackstate, setSnackState] = React.useState({
    vertical: 'bottom',
    horizontal: 'right',
  });
  const { vertical, horizontal } = snackstate;

  const handleInputChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.id]: e.target.value.trim()
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const str = $("#Maraton").serialize();
    setIsLoading(true);
    fetch('https://script.google.com/macros/s/AKfycbzQwcjO16fBj_oPn6JZ6PZ1ebI7EV5tclq_fl6Yx5plRkGVBsPWM19Kvg/exec', {
      method: 'POST',
      mode: 'no-cors',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: str
    }).then(response => {
      // if (response.status >= 200 && response.status < 300) {
      // console.log("Sucesssss", response);
      setIsLoading(false);
      setOpen(true);
      $(".quote-form").trigger("reset");
      return response;
    }).catch(err => {
      console.log(err);
      setOpenError(true);
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };


  const data = useStaticQuery(graphql`
    query {
      contactus: file(relativePath: { eq: "contactus.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }      
    }
  `);


  return (

    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "innerpage-contact" })} />

        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.contactus.childImageSharp.fluid}
                className="img-responsive"
              />

            </div>
          </FadeIn>
          <div>
            <div className="bodyTextCss">
              <FadeIn className="BodyContainer bodytext" delay={210} transitionDuration={500}>
                <div className="contactBox">
                <div className="">
                  <div className="assessment">{intl.formatMessage({ id: "innerpage-contact" })}</div>
                </div>
                <form onSubmit={handleSubmit} method="post" id="Maraton" name="Maraton" className="quote-form">
                  {
                    formFields && formFields.map(data => {

                      let fields = "";

                      if (data.id === "TipodeSeguro") {
                        fields = (
                          <div className="form-group" key={data.id}>
                            <label htmlFor={data.id} className="text-dark"><span className="required">*</span>{data.name}</label>
                            <select onChange={handleInputChange} maxLength={data.maxLength} required id={data.id} name={data.name}>
                              {
                                data.options && data.options.map((option, index) => {
                                  return (
                                    <option key={index} value={option == "Please select" ? "" : option}>{option}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        )
                      }

                      else if (data.id === "Message") {
                        fields = (
                          <div className="form-group" style={{ width: "100%" }} key={data.id}>
                            <label htmlFor={data.id} className="text-dark"><span className="required">*</span>{data.name}</label>

                            <TextField
                              required
                              id="outlined-multiline-static"
                              label={data.name}
                              variant="outlined"
                              multiline
                              rows={4}
                              onChange={handleInputChange}
                              maxLength={data.maxLength}
                              type={data.type}
                              id={data.id}
                              name={data.name}
                              pattern={data.pattern}
                            />

                          </div>
                        )
                      }

                      else {
                        fields = (
                          <div className="form-group" key={data.id}>
                            <label htmlFor={data.id} className="text-dark"> {data.name} <span className="required">*</span></label>

                            <TextField
                              required
                              id="outlined-required"
                              label={data.name}
                              variant="outlined"
                              onChange={handleInputChange}
                              maxLength={data.maxLength}
                              type={data.type}
                              id={data.id}
                              name={data.name}
                              pattern={data.pattern}
                            />
                          </div>
                        )
                      }
                      return fields;
                    })
                  }
                  <div className="quote-btn-wrapper">
                    <button disabled={isLoading} className="btnmt btn-1 btn-1c quote-btn" type="submit">
                      <span>{intl.formatMessage({ id: "innerpage-submit" })}</span>
                      {
                        isLoading && <CircularProgress style={{
                          width: "20px",
                          height: "20px",
                          float: "right"
                        }} />
                      }
                    </button>
                  </div>
                </form>
                </div>
              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
      {/* <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} >
        <Alert onClose={handleClose} severity="success">
          Contact Details has been sent successfully!
                </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={5000} onClose={handleCloseError} anchorOrigin={{ vertical, horizontal }} >
        <Alert onClose={handleCloseError} severity="error">
          Contact Details submission has been failed!
                </Alert>
      </Snackbar> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{
          color: "#03b509",
          textAlign: "center",
          fontSize: "26px",
          textTransform: "capitalize"
        }}>{"Contact Form sent successfully!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{
            textAlign: "center",
            color: "#555",
            fontSize: "15px"
          }}>
              Thank you for submitting your assessment request. <br/>We will get back to you shortly<br/> - Team Maraton
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>

  );
};

export default ContactUs;
